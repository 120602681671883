<template>
	<div class="p-grid">
		<div v-if="userData !== undefined && userHasRole('ROLE_SUPERVISOR')" class="p-col-12">
			<DataTable :value="users" :lazy="true" class="p-datatable-gridlines" dataKey="id" :paginator="true" :rows="rows"
					   :totalRecords="totalEntities" :rowHover="false" :loading="loading" @page="onPage($event)" @sort="onSort($event)" responsiveLayout="scroll">
				<template #header>
					<div class="p-d-flex p-jc-between p-ai-center">
						<div class="left">
							<Button :disabled="userData.used_quota >= userData.quota" label="Créer un utilisateur" icon="pi pi-user-plus"
									@click="resetWizard" class="b-button p-button"></Button>
						</div>

						<div class="right">
							<span v-if="userData.used_quota > 1">Utilisateurs</span><span v-else>Utilisateur</span> {{ userData.used_quota }}/{{ userData.quota }}
						</div>
					</div>
				</template>
				<template #empty>
					<div class="p-fluid p-text-center">
						<small class="text-muted">Pas d'utilisateur 😔<br><a @click.prevent="resetWizard" href="#">Créez en un dès maintenant !</a></small>
					</div>
				</template>
				<template #loading>
					<small class="text-muted">Chargement des utilisateurs</small>
				</template>
				<Column field="username" header="E-mail"></Column>
				<Column field="firstname" header="Nom complet">
					<template #body="{data}">
						{{ data.firstname }} {{ data.lastname }}
					</template>
				</Column>
				<Column field="poste" header="Poste"></Column>

				<Column field="firstname" header="Quota médias">
					<template #body="{data}">
						{{ data.used_quota_public_file }}/{{ data.quota_public_file }}
					</template>
				</Column>
				<Column field="cardStatus" header="Carte">
					<template #body="{data}">
						{{ statusIntToStr(data.cardStatus,false) }}
					</template>
				</Column>
				<Column field="id" header="">
					<template #body="{data}">
						<div class="p-fluid p-text-center">
							<Button icon="pi pi-trash" label="Supprimer" @click="data.deleteDialog = true" class="p-button-outlined p-button-danger"></Button>
						</div>
						<Dialog header="Confirmation" v-model:visible="data.deleteDialog" :style="{width: '350px'}" :modal="true">
							<div class="confirmation-content">
								<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
								<span>Êtes-vous sur de vouloir supprimer cet utilisateur ?</span>
							</div>
							<template #footer>
								<Button label="Non" icon="pi pi-times" @click="data.deleteDialog = false" class="p-button-text"/>
								<Button label="Oui, supprimer !" icon="pi pi-check" @click="deleteSubAccount(data)" class="p-button-danger" autofocus />
							</template>
						</Dialog>
					</template>
				</Column>
			</DataTable>

			<Dialog :style="{'max-width': '550px',width: '90%'}" v-model:visible="wizardDialog" :modal="true"  >
				<template #header>
					<h5>Assitant de création d'utilisateur - <small class="text-muted">étape {{ wizardStep+1 }}</small></h5>
				</template>

				<div class="modal-content p-fluid">
					<div v-if="wizardStep === 0" class="p-fluid">
						<p>Les sous-utilisateurs vous permettent de donner l'accès à Freecard à toutes personne de votre société.</p>
						<Message severity="warn" :closable="false">
							ATTENTION : <b>un mot de passe sera généré et envoyé à l'email renseigné ci-dessous</b>.
						</Message>
						<p>Commencez par fournir l'email du nouvel utilisateur :</p>
						<div class="p-field">
							<span class="p-input-icon-left">
								<i class="pi pi-envelope" />
								<InputText type="email"  v-model="wizardCampaignName" placeholder="utilisateur@domaine.fr" />
							</span>
						</div>
					</div>
					<div v-else-if="wizardStep === 1" class="p-fluid">
						<p>Cet utilisateur doit-il disposer d'une carte de contact rapide NFC ?</p>
						<div class="p-field">
							<div class="p-field-radiobutton">
								<RadioButton name="permanente" :value="0" v-model="wizardCampaignType" />
								<label for="permanente"><b>Utilisateur sans carte</b></label>
							</div>
							<div class="p-fluid p-mb-4">
								<small class="text-muted">
									Un utilisateur sans carte aura accès aux mêmes fonctionnalités mais vous ne recevrez
									 aucune carte physique pour celui-ci.
								</small>
							</div>

							<div class="p-field-radiobutton">
								<RadioButton name="temporaire" :value="1" v-model="wizardCampaignType" />
								<label for="temporaire"><b>Utilisateur avec carte</b></label>
							</div>
						</div>

						<div v-if="wizardCampaignType === 1" class="p-fluid">
							<Message severity="warn" :closable="false">
								ATTENTION : <b>un certain délais est à prévoir entre la création de l'utilisateur
								et la réception de la carte physique</b>.
							</Message>
						</div>

					</div>
					<div v-else-if="wizardStep === 2" class="p-fluid">
						<div v-if="wizardCampaignType === 1" class="p-fluid">
							<p>Un utilisateur avec carte doit être créé avec les informations suivantes :</p>
							<div class="p-field">
								<label for="name1">Nom</label>
								<InputText v-model="wizardAddr.lastname"
										   id="street" type="text" />
							</div>
							<div class="p-field">
								<label for="name1">Prénom</label>
								<InputText v-model="wizardAddr.firstname" id="street" type="text" />
							</div>
							<div class="p-field">
								<label for="name1">Poste</label>
								<InputText v-model="wizardAddr.poste" id="postalcode" type="text" />
							</div>
						</div>
						<div v-else class="p-fluid">
							<p>Un utilisateur sans carte n'a pas de pré-requis d'informations.</p>
						</div>
					</div>
					<div v-else-if="wizardStep === 3" class="p-fluid">
						<div v-if="wizardCampaignType === 0" class="p-fluid">
							<p>Votre utilisateur {{ wizardCampaignName }} est prêt à être créé !</p>
						</div>
						<div v-else class="p-fluid">
							<div class="p-text-center p-mb-3">
								<p><br>
									<span>{{ wizardAddr.firstname }} {{ wizardAddr.lastname }}</span><br>
									<span>{{ wizardAddr.poste }}</span><br>
									<span><small>{{ wizardCampaignName }}</small></span><br>
								</p>
							</div>
							<p>Votre utilisateur est prêt à être créé !</p>
						</div>

					</div>
				</div>

				<template #footer>
					<Button v-if="wizardStep === 0" @click="wizardDialog = false" label="Annuler" icon="pi pi-times" class="p-button-danger"/>
					<Button v-if="wizardStep > 0" @click="--wizardStep" label="Précédent" icon="pi pi-arrow-left" class="p-button-text"/>
					<Button label="Suivant" v-if="wizardStep < 3" :disabled="nextDisabled"  icon="pi pi-arrow-right" @click="wizardStep++" autofocus />
					<Button label="Créer l'utilisateur" v-if="wizardStep === 3" class="p-button-success"  icon="pi pi-save" @click="createAccount" autofocus />
				</template>
			</Dialog>

		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import calendarMixin from "../../../mixins/calendarMixin";
	import ProfileService from "../../../service/ProfileService";
	import userMixin from "../../../mixins/userMixin";


	export default {
		data() {
			return {
				dut: undefined,
				users: [],
				totalEntities: 0,
				rows: 10,
				loading: false,
				order: undefined,
				orderDirection: false,
				page: 1,
				statusFilter: undefined,
				timeoutPtr: undefined,
				wizardDialog: false,
				wizardStep: 0,
				wizardCampaignType: 0,
				wizardCampaignName: '',
				wizardAddr: {
					firstname: '',
					lastname: '',
					poste: '',
				}
			}
		},
		created(){
			this.profileService = new ProfileService();
		},
		mounted(){
			this.loadData()
			;
		},
		methods: {
			deleteSubAccount(account){
				const vm = this;
				this.profileService.deleteAccount(account)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							window.appData.services.profile.getProfileInfo()
									.then(data => {
										if(data.error !== undefined && data.error === true){
											document.location.reload();
											return;
										}
										vm.loadData();
									})
									.catch(err => {
										console.warn(err);
										document.location.reload();
									})

						})
			},
			createAccount() {
				const vm = this;
				const account = {
					username: this.wizardCampaignName,
					firstname: this.wizardAddr.firstname,
					lastname: this.wizardAddr.lastname,
					poste: this.wizardAddr.poste,
					cardStatus: 0,
					owner: '/api/users/' + this.userData.id,
				};

				if(this.wizardCampaignType === 1){
					account.cardStatus = 2;
				}

				this.profileService.createAccount(account)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.wizardDialog = false;
							window.appData.services.profile.getProfileInfo()
									.then(data => {
										if(data.error !== undefined && data.error === true){
											document.location.reload();
											return;
										}
										vm.loadData();
									})
									.catch(err => {
										console.warn(err);
										document.location.reload();
									})
						})
			},
			resetWizard(){
				this.wizardDialog = true;
				this.wizardStep = 0;
				this.wizardCampaignType =0;
				this.wizardCampaignName = '';
				this.wizardAddr = {
					firstname: '',
					lastname: '',
					poste: '',
				};
			},
			registerReload(){
				const vm = this;
				if(this.timeoutPtr !== undefined){
					this.cancelReload();
				}
				this.timeoutPtr = setTimeout(() => {
					vm.loadData();
				}, 400)
			},
			cancelReload(){
				try {
					clearTimeout(this.timeoutPtr);
				} catch (e) {
					console.warn(e);
				}
			},
			viewContact(contact) {
				this.$router.push({ name: 'crm-contacts-profile', params: { guid: contact.guid } })
			},
			onPage(event) {
				this.page = event.page + 1;
				this.loadData();
			},
			onSort(event) {
				console.log(event);
			},
			loadData(){
				const vm = this;
				this.loading = true;
				this.profileService.getSubUsers(
						this.rows,
						this.page,
						this.orderDirection,
				)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

							const p = data.data;
							vm.users = p.data;
							vm.totalEntities = p['@meta'].total;

						})
						.catch(err => {
							vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							vm.loading = false;
						})
			}
		},
		computed:{
			...mapState({
				userData: 'user',
			}),
			nextDisabled(){
				let reg = new RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$', 'i');

				let isDisabled = false;
				switch (this.wizardStep) {
					case 0:
						isDisabled = true;
						if(this.wizardCampaignName !== '' && reg.test(this.wizardCampaignName)){
							isDisabled = false;
						}
						break;
					case 2:
						isDisabled = true;
						if(this.wizardCampaignType === 0){
							isDisabled = false;
						} else {
							if(this.wizardAddr.firstname !== '' && this.wizardAddr.lastname !== '' ){
								isDisabled = false;
							}
						}
						break;
					default:
						isDisabled = false;
				}
				return isDisabled;
			}
		},
		components: {
		},
		mixins:[calendarMixin, userMixin]
	}
</script>

<style scoped>

</style>
