<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Utilisateurs de mon organisation</h1>
			<p class="text-muted">Les sous-utilisateurs vous permettent de donner accès à Freecard à tous vos collaborateurs.
				Depuis cette page vous pouvez créer et manager de nouveaux utilisateurs.</p>
		</div>

		<div class="p-col-12">
			<div class="card">
				<SubUsersList></SubUsersList>
			</div>
		</div>
	</div>
</template>

<script>
	import SubUsersList from '../components/app/supervisor/SubUsersList.vue'
export default {

		components: {
			SubUsersList,
		}
}
</script>

<style scoped>

</style>
